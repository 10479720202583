import React from "react";
import SettingStoreOption from "./SettingStoreOption";

const MainStoreOption = () => {
  return (
    <>
      <div className="" style={{ paddingBottom: "120px" }}>
        <SettingStoreOption />
      </div>
    </>
  );
};

export default MainStoreOption;
