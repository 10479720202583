import React from "react";
import SystemView from "./SystemView";


const MainSyastemAccess = () => {
  return (
    <>
    
      <div className="q-attributes-main-page">
        <SystemView />
      </div>
    </>
  );
};

export default MainSyastemAccess;
