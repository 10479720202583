import React from "react";
import InventoryView from "./InventoryView";

function MainInventory() {
  return (
    <>

      <div className="q-category-main-page">
        <InventoryView />
      </div>
    </>
  );
}

export default MainInventory;
