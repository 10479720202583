import React from "react";
import EmployeelistReport from "./EmployeelistReport";

const MainEmployeelist = () => {
  return (
    <>
      <div className="q-order-main-page">
        <EmployeelistReport />
      </div>
    </>
  );
};

export default MainEmployeelist;
